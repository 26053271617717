module.exports = [{
      plugin: require('../plugins/gatsby-plugin-nerd-custom-page/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Purina.com","short_name":"Purina","start_url":"/","background_color":"#e81c24","display":"minimal-ui","icon":"src/assets/media/images/favicon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4ad9943a63c5d8afc4ecc9019555131c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","es"],"defaultLanguage":"en","trailingSlash":"never","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true},{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 575px)","sm":"(min-width: 576px)","md":"(min-width: 768px)","lg":"(min-width: 992px)","xl":"(min-width: 1200px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../plugins/gatsby-plugin-purina-analytics/gatsby-browser.tsx'),
      options: {"plugins":[],"recaptchaSiteKey":"6Lcy0BAcAAAAADTT-NV2VzcBJxU-qFtK-yO8ukwf","gtmID":"GTM-KBH7G46","dataLayerName":"dataLayer","siteID":"purina.com","routeChangeEventName":"page_view","onetrustID":"85c387a5-4169-4e58-9be4-076a3baafb92","segmentKey":"sAP9ENyw13wRy7ZhEHywwNuQgMIJ37Ix"},
    },{
      plugin: require('../plugins/gatsby-plugin-bazaarvoice/gatsby-browser.tsx'),
      options: {"plugins":[],"clientName":"purina","siteID":"main_site","mode":"production"},
    },{
      plugin: require('../plugins/gatsby-plugin-pricespider/gatsby-browser.tsx'),
      options: {"plugins":[],"accountID":"2958","defaultWidgetID":"5bd0c6dd2b613f029698c72e","country":"US"},
    },{
      plugin: require('../node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"6448102ab113ef133605c088","bootstrap":"localStorage"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
